import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { message } from 'antd'
import { useHistory } from 'react-router'

import { syncCertificates } from '../services'
import { handleError } from '../../../helpers'

export default function CertificateForm() {
	const user = useSelector(state => state.auth.user)
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
		defaultValues: { year: '2023' }
	})

	const onSubmit = values => {
		setLoading(true)
		syncCertificates({
			'supplier_document': user.document,
			'type': values.type,
			'year': values.year,
			'month': values.month,
		})
			.then(res => {
				if(res.data.data.length === 0){
					message.warning(res.data.message)
					setValue('month', '')
					setLoading(false)
				} else {
					history.push(`/certificados/${values.type}/${values.year}/${values.month}`)
				}
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group className='form-group'>
					<Form.Label>Tipo de Certificado <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('type', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">1 - Retencion IVA</option>
						<option value="2">2 - Retencion ICA</option>
						<option value="3">3 - Retencion en la Fuente</option>
					</Form.Control>
					{ errors.type && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Año Fiscal <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('year', { required:true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option>2023</option>
						<option>2024</option>
					</Form.Control>
					{ errors.year && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Periodo <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('month', { required:true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">1 - Enero</option>
						<option value="2">2 - Febrero</option>
						<option value="3">3 - Marzo</option>
						<option value="4">4 - Abril</option>
						<option value="5">5 - Mayo</option>
						<option value="6">6 - Junio</option>
						<option value="7">7 - Julio</option>
						<option value="8">8 - Agosto</option>
						<option value="9" disabled={watch('year') === '2024'}>9 - Septiembre</option>
						<option value="10" disabled={watch('year') === '2024'}>10 - Octubre</option>
						<option value="11" disabled={watch('year') === '2024'}>11 - Noviembre</option>
						<option value="12" disabled={watch('year') === '2024'}>12 - Diciembre</option>
					</Form.Control>
					{ errors.month && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Button color='primary' type="submit" disabled={loading}>
					{loading && <Spinner animation='border' size="sm" className='mr-5' />}
					Consultar Certificado
				</Button>
			</Form>
		</React.Fragment>
	)
}